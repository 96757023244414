import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Text, View, Document, Page, Font, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import HeaderPDF from 'components/Core/PDF/HeaderPDF';
import Axios from "axios";
import { initAxiosInterceptors } from "utils/axiosConfig";
import { useDialog } from "context/DialogContext";
import { useLoading } from "context/LoadingContext";
import RobotoSlabBold from '../../static/fonts/RobotoSlab-Bold.ttf';
import Roboto from '../../static/fonts/Roboto-Regular.ttf';
import RobotoBold from '../../static/fonts/Roboto-Bold.ttf';
import Header from 'components/material-kit-pro-react/components/Header/Header';
import HeaderLinks from 'components/material-kit-pro-react/components/Header/HeaderLinks';
import styles from 'components/Core/PDF/comparePDFStyles';
import { isIOS, isAndroid } from "react-device-detect"
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

// Load PDFViewer dynamically
const PDFViewer = lazy(() => import('@react-pdf/renderer').then(module => ({ default: module.PDFViewer })));

const insuranceCompany = process.env.GATSBY_INSURANCE_COMPANY;
const colorBackgroundTitle = insuranceCompany === 'OCEANICA' ? '#47C0B6' : '#CC2229';

Font.register({ family: 'Roboto Slab', src: RobotoSlabBold });
Font.register({
  family: 'Roboto', fonts: [
    { src: Roboto },
    { src: RobotoBold, fontWeight: 700 }
  ]
});

const comparePDFStyles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',

  },
  pageDiv: {
    padding: "0 20px",
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: "sans-serif",
    width:"100%",
    overflow:"auto"

  },

  tableHeader: {
    backgroundColor: colorBackgroundTitle,
    color: "white",
    fontWeight: "bold",
    marginTop: "30px",
    display: "flex",
    flexDirection: 'row',

  },
  tableHeaderDiv: {
    backgroundColor: colorBackgroundTitle,
    color: "white",
    fontWeight: "bold",
    marginTop: "30px",
    display: "flex",
    flexDirection: 'row',
    width: "1200px",

  },
  tableItemSalud: {
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    display: "flex",
    flexDirection: 'row',


  },
  tableItemSaludDiv: {
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    display: "flex",
    flexDirection: 'row',
    width: "1200px"


  },
  tableItemAuto: {
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    display: "flex",
    flexDirection: 'row',

  },
  tableTotales: {
    backgroundColor: "#dbd1d1",
    color: "black",
    fontWeight: "bold",
    display: "flex",
    flexDirection: 'row',

  },
  tableTotalesDiv: {
    backgroundColor: "#dbd1d1",
    color: "black",
    fontWeight: "bold",
    display: "flex",
    flexDirection: 'row',
    width: "1200px"

  },
  item: {
    width: "100%",
    textAlign: "right",
    border: 1,
    borderRight: 0,
    borderBottom: 0,
    borderColor: 'black',
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 8,


  },
  itemDiv: {
    width: "100%",
    textAlign: "right",
    border: "solid 1px",
    borderRight: 0,
    borderBottom: 0,
    borderColor: 'black',
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 8,


  },
  itemTotal: {
    width: "100%",
    textAlign: "right",
    border: 1,
    borderColor: 'black',
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 9,
    fontWeight: 700,
    backgroundColor: "gray"

  },
  itemTotalFinal: {
    width: "100%",
    textAlign: "right",
    border: 1,
    borderRight: 0,
    borderColor: 'black',
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 9,
    fontWeight: 700

  },

  itemTotalFinalDiv: {
    width: "100%",
    textAlign: "right",
    border: "1px solid",
    borderRight: 0,
    borderColor: 'black',
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 9,
    fontWeight: 700

  },

  itemTotalFinalRight: {
    width: "100%",
    textAlign: "right",
    border: 1,
    borderColor: 'black',
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 9,
    fontWeight: 700

  },
  itemTotalFinalRightDiv: {
    width: "100%",
    textAlign: "right",
    border: "1px solid",
    borderColor: 'black',
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 9,
    fontWeight: 700

  },

  titleText: {
    width: "100%",
    textAlign: "center",
    borderLeft: 1,
    borderColor: 'black',
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 8

  },
  titleTextDiv: {
    width: "100%",
    textAlign: "center",
    borderLeft: "solid 1px",
    borderColor: 'black',
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 8

  },
  titleTextHidden: {
    width: "120%",
    textAlign: "center",
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 8

  },
  titleTextItem: {
    width: "120%",
    textAlign: "left",
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 8,
    border: 1,
    borderColor: "black",
    borderBottom: 0,
    borderRight: 0

  },
  titleTextItemTotal: {
    border: 1,
    borderRight: 0,
    borderColor: "black",
    width: "120%",
    // textAlign: "right",
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 8

  },
  titleTextItemTotalDiv: {
    border: "solid 1px",
    borderRight: 0,
    borderColor: "black",
    borderBottom:0,
    width: "120%",
    // textAlign: "right",
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 8

  },
  titleTextItemTotalDivEnd: {
    border: "solid 1px",
    borderBottom: "solid 1px",
    borderColor: "black",
    width: "120%",
    borderRight: 0,
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 8

  },
  titleTextItemSeparator: {
    width: "120%",
    textAlign: "left",
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 8,
    backgroundColor: "white",
    color: "black",
    textAlign: "right"

  },
  itemText: {
    width: "100%",
    textAlign: "right",
    borderLeft: 1,
    borderColor: 'red',
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 8

  },
  itemTextRightFinal: {

    border: 1,
    borderBottom: 0,
    width: "100%",
    textAlign: "right",
    border: 1,
    borderColor: 'black',
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 8,

  },
  itemTextRightFinalDiv: {

    border: "solid 1px",
    borderBottom: 0,
    width: "100%",
    textAlign: "right",
    borderColor: 'black',
    padding: "10px 5px",
    justifyContent: 'center',
    fontSize: 8,

  },




})

export default function Calendarizacion() {
  const [arrayDetailSalud, setArrayDetailSalud] = useState([]);
  const [arrayDetailAuto, setArrayDetailAuto] = useState([]);
  const [arrayDetailSaludTotal, setArrayDetailSaludTotal] = useState([]);
  const [arrayDetailAutoTotal, setArrayDetailAutoTotal] = useState([]);
  const [arrayTotales, setArrayTotales] = useState([]);
  const [currentDate, setCurrentDate] = useState('');
  const dialog = useDialog();
  const loading = useLoading();


  useEffect(() => {
    initAxiosInterceptors(dialog, loading);
    getCalendaringInfo();
  }, []);


  async function getCalendaringInfo() {
    try {
      const response = await Axios.post("/dbo/toolkit/dev_data_calendarizacion");
      if (response) {
        const filteredDataSalud = response.data.c_detalle.filter(item => item.CODAREA === "0004");
        setArrayDetailSalud(filteredDataSalud);

        const filteredDataAuto = response.data.c_detalle.filter(item => item.CODAREA === "0002");
        setArrayDetailAuto(filteredDataAuto);

        const filteredDataSaludTotal = response.data.c_totaldet.filter(item => item.CODAREA === "0004");
        setArrayDetailSaludTotal(filteredDataSaludTotal);

        const filteredDataAutoTotal = response.data.c_totaldet.filter(item => item.CODAREA === "0002");
        setArrayDetailAutoTotal(filteredDataAutoTotal);

        setArrayTotales(response.data.c_total);
        setCurrentDate(response.data.c_total[0]?.FECHA);
      }
    } catch (error) {
      console.log(error);
    }
  }





  return (
    <>
      <div style={{
        position: "fixed",
        width: "100%",
        top: 0,
      }}>
        <Header
          links={<HeaderLinks dropdownHoverColor="primary" />}
          color={"white"}
        />
      </div>
      <div style={{marginBottom:"100px"}}/>
      <Suspense fallback={<div>Loading PDF...</div>}>
        {(isIOS || isAndroid) ? (
          <>
            <PDFDownloadLink style={{
              backgroundColor: colorBackgroundTitle,
              margin: 'auto',
              display: 'block',
              width: '200px',
              textAlign: 'center',
              padding: '10px',
              borderRadius: '10px',
              color: 'white',
              marginTop: '30px',
              marginBottom: '20px',
              fontSize: '13px',
              fontWeight: 'bold',
            }}
              document={<Document>
                <Page size="A2" style={styles.page} key={1}>
                  <HeaderPDF />
                  <View style={{ textAlign: "center", width: "100%", marginTop: "20px", fontWeight: 700 }}>
                    <Text style={{ fontSize: "20px" }}>Calendarización de Pago al {currentDate}</Text>
                  </View>
                  <View style={comparePDFStyles.tableHeader}>
                    <Text style={comparePDFStyles.titleTextHidden}></Text>
                    <Text style={comparePDFStyles.titleText}>Menos de 30 días</Text>
                    <Text style={comparePDFStyles.titleText}>Cant Menos 30 </Text>
                    <Text style={comparePDFStyles.titleText}>Entre 31 y 60 días</Text>
                    <Text style={comparePDFStyles.titleText}>Cant 31 y 60</Text>
                    <Text style={comparePDFStyles.titleText}>Entre 61 y 90 días</Text>
                    <Text style={comparePDFStyles.titleText}>Cant 61 y 90</Text>
                    <Text style={comparePDFStyles.titleText}>Más de 90 días</Text>
                    <Text style={comparePDFStyles.titleText}>Cant más 90</Text>
                    <Text style={comparePDFStyles.titleText}>Totales</Text>
                    <Text style={comparePDFStyles.titleText}>Cant Total</Text>
                  </View>
                  {arrayDetailSalud?.map((item, index) => (
                    <View style={comparePDFStyles.tableItemSalud} key={index}>
                      <Text style={comparePDFStyles.titleTextItem}>{item.DESCRIP}</Text>
                      <Text style={comparePDFStyles.item}>{item.MTO30DIAS}</Text>
                      <Text style={comparePDFStyles.item}>{item.CANT30}</Text>
                      <Text style={comparePDFStyles.item}>{item.MTO60DIAS}</Text>
                      <Text style={comparePDFStyles.item}>{item.CANT60}</Text>
                      <Text style={comparePDFStyles.item}>{item.MTO90DIAS}</Text>
                      <Text style={comparePDFStyles.item}>{item.CANT90}</Text>
                      <Text style={comparePDFStyles.item}>{item.MTOMAS90DIAS}</Text>
                      <Text style={comparePDFStyles.item}>{item.CANTMAS90}</Text>
                      <Text style={comparePDFStyles.item}>{item.TOTAL}</Text>
                      <Text style={comparePDFStyles.itemTextRightFinal}>{item.CANTTOTAL}</Text>
                    </View>
                  ))}
                  {arrayDetailSaludTotal?.map((item, index) => (
                    <View style={comparePDFStyles.tableTotales} key={index}>
                      <Text style={comparePDFStyles.titleTextItemTotal}>TOTAL SALUD</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO30DIAS}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT30TOT}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO60DIAS}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT60TOT}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO90DIAS}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT90TOT}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.MTOMAS90DIAS}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.CANTMAS90TOT}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALAREA}</Text>
                      <Text style={comparePDFStyles.itemTotalFinalRight}>{item.CANTTOTALSUM}</Text>
                    </View>
                  ))}
                  <View style={{ marginBottom: "10px" }} />


                  {arrayDetailAuto?.map((item, index) => (
                    <View style={comparePDFStyles.tableItemAuto} key={index}>
                      <Text style={comparePDFStyles.titleTextItem}>{item.DESCRIP}</Text>
                      <Text style={comparePDFStyles.item}>{item.MTO30DIAS}</Text>
                      <Text style={comparePDFStyles.item}>{item.CANT30}</Text>
                      <Text style={comparePDFStyles.item}>{item.MTO60DIAS}</Text>
                      <Text style={comparePDFStyles.item}>{item.CANT60}</Text>
                      <Text style={comparePDFStyles.item}>{item.MTO90DIAS}</Text>
                      <Text style={comparePDFStyles.item}>{item.CANT90}</Text>
                      <Text style={comparePDFStyles.item}>{item.MTOMAS90DIAS}</Text>
                      <Text style={comparePDFStyles.item}>{item.CANTMAS90}</Text>
                      <Text style={comparePDFStyles.item}>{item.TOTAL}</Text>
                      <Text style={comparePDFStyles.itemTextRightFinal}>{item.CANTTOTAL}</Text>
                    </View>
                  ))}
                  {arrayDetailAutoTotal?.map((item, index) => (
                    <View style={comparePDFStyles.tableTotales} key={index}>
                      <Text style={comparePDFStyles.titleTextItemTotal}>TOTAL AUTOMÓVIL</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO30DIAS}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT30TOT}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO60DIAS}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT60TOT}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO90DIAS}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT90TOT}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.MTOMAS90DIAS}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.CANTMAS90TOT}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALAREA}</Text>
                      <Text style={comparePDFStyles.itemTotalFinalRight}>{item.CANTTOTALSUM}</Text>
                    </View>
                  ))}
                  <View style={{ marginBottom: "20px" }} />
                  {arrayTotales?.map((item, index) => (
                    <View style={comparePDFStyles.tableTotales} key={index}>
                      <Text style={comparePDFStyles.titleTextItemTotal}>TOTAL GENERAL</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO30DIAS}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT30TOT}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO60DIAS}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT60TOT}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO90DIAS}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT90TOT}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.MTOMAS90DIAS}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.CANTMAS90TOT}</Text>
                      <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALAREA}</Text>
                      <Text style={comparePDFStyles.itemTotalFinalRight}>{item.CANTTOTALSUM}</Text>
                    </View>
                  ))}
                </Page>
              </Document>} fileName={"Calendarización_" + currentDate + ".pdf"}>
              {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Descargar Calendarización')}
            </PDFDownloadLink>
            <div style={{ textAlign: "center", width: "100%", marginTop: "30px", fontWeight: 700 }}>
                <div style={{ fontSize: "20px", }}>Calendarización de Pago al {currentDate}</div>
              </div>
            <div size="A2" style={comparePDFStyles.pageDiv} key={1}>
              <div style={comparePDFStyles.tableHeaderDiv}>
                <div style={comparePDFStyles.titleTextHidden}></div>
                <div style={comparePDFStyles.titleTextDiv}>Menos de 30 días</div>
                <div style={comparePDFStyles.titleTextDiv}>Cant Menos 30 </div>
                <div style={comparePDFStyles.titleTextDiv}>Entre 31 y 60 días</div>
                <div style={comparePDFStyles.titleTextDiv}>Cant 31 y 60</div>
                <div style={comparePDFStyles.titleTextDiv}>Entre 61 y 90 días</div>
                <div style={comparePDFStyles.titleTextDiv}>Cant 61 y 90</div>
                <div style={comparePDFStyles.titleTextDiv}>Más de 90 días</div>
                <div style={comparePDFStyles.titleTextDiv}>Cant más 90</div>
                <div style={comparePDFStyles.titleTextDiv}>Totales</div>
                <div style={comparePDFStyles.titleTextDiv}>Cant Total</div>
              </div>
              {arrayDetailSalud?.map((item, index) => (
                <div style={comparePDFStyles.tableItemSaludDiv} key={index}>
                  <div style={comparePDFStyles.titleTextItemTotalDiv}>{item.DESCRIP}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.MTO30DIAS}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.CANT30}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.MTO60DIAS}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.CANT60}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.MTO90DIAS}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.CANT90}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.MTOMAS90DIAS}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.CANTMAS90}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.TOTAL}</div>
                  <div style={comparePDFStyles.itemTextRightFinalDiv}>{item.CANTTOTAL}</div>
                </div>
              ))}
              {arrayDetailSaludTotal?.map((item, index) => (
                <div style={comparePDFStyles.tableTotalesDiv} key={index}>
                  <div style={comparePDFStyles.titleTextItemTotalDivEnd}>TOTAL SALUD</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.TOTALMTO30DIAS}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.CANT30TOT}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.TOTALMTO60DIAS}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.CANT60TOT}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.TOTALMTO90DIAS}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.CANT90TOT}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.MTOMAS90DIAS}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.CANTMAS90TOT}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.TOTALAREA}</div>
                  <div style={comparePDFStyles.itemTotalFinalRightDiv}>{item.CANTTOTALSUM}</div>
                </div>
              ))}

              <div style={{ marginBottom: "10px" }} />
              {arrayDetailAuto?.map((item, index) => (
                <div style={comparePDFStyles.tableItemSaludDiv} key={index}>
                  <div style={comparePDFStyles.titleTextItemTotalDiv}>{item.DESCRIP}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.MTO30DIAS}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.CANT30}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.MTO60DIAS}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.CANT60}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.MTO90DIAS}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.CANT90}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.MTOMAS90DIAS}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.CANTMAS90}</div>
                  <div style={comparePDFStyles.itemDiv}>{item.TOTAL}</div>
                  <div style={comparePDFStyles.itemTextRightFinalDiv}>{item.CANTTOTAL}</div>
                </div>
              ))}
              {arrayDetailAutoTotal?.map((item, index) => (
                <div style={comparePDFStyles.tableTotalesDiv} key={index}>
                  <div style={comparePDFStyles.titleTextItemTotalDivEnd}>TOTAL AUTOMÓVIL</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.TOTALMTO30DIAS}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.CANT30TOT}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.TOTALMTO60DIAS}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.CANT60TOT}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.TOTALMTO90DIAS}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.CANT90TOT}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.MTOMAS90DIAS}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.CANTMAS90TOT}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.TOTALAREA}</div>
                  <div style={comparePDFStyles.itemTotalFinalRightDiv}>{item.CANTTOTALSUM}</div>
                </div>
              ))}
              <div style={{ marginBottom: "20px" }} />
              {arrayTotales?.map((item, index) => (
                <div style={comparePDFStyles.tableTotalesDiv} key={index}>
                  <div style={comparePDFStyles.titleTextItemTotalDivEnd}>TOTAL GENERAL</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.TOTALMTO30DIAS}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.CANT30TOT}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.TOTALMTO60DIAS}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.CANT60TOT}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.TOTALMTO90DIAS}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.CANT90TOT}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.MTOMAS90DIAS}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.CANTMAS90TOT}</div>
                  <div style={comparePDFStyles.itemTotalFinalDiv}>{item.TOTALAREA}</div>
                  <div style={comparePDFStyles.itemTotalFinalRightDiv}>{item.CANTTOTALSUM}</div>
                </div>
              ))}
            </div>


          </>
        ) :
          <PDFViewer title="Mi Documento PDF" width="100%" height={"1200px"}>
            <Document>
              <Page size="A2" style={styles.page} key={1}>
                <HeaderPDF />
                <View style={{ textAlign: "center", width: "100%", marginTop: "20px", fontWeight: 700 }}>
                  <Text style={{ fontSize: "20px" }}>Calendarización de Pago al {currentDate}</Text>
                </View>
                <View style={comparePDFStyles.tableHeader}>
                  <Text style={comparePDFStyles.titleTextHidden}></Text>
                  <Text style={comparePDFStyles.titleText}>Menos de 30 días</Text>
                  <Text style={comparePDFStyles.titleText}>Cant Menos 30 </Text>
                  <Text style={comparePDFStyles.titleText}>Entre 31 y 60 días</Text>
                  <Text style={comparePDFStyles.titleText}>Cant 31 y 60</Text>
                  <Text style={comparePDFStyles.titleText}>Entre 61 y 90 días</Text>
                  <Text style={comparePDFStyles.titleText}>Cant 61 y 90</Text>
                  <Text style={comparePDFStyles.titleText}>Más de 90 días</Text>
                  <Text style={comparePDFStyles.titleText}>Cant más 90</Text>
                  <Text style={comparePDFStyles.titleText}>Totales</Text>
                  <Text style={comparePDFStyles.titleText}>Cant Total</Text>
                </View>
                {arrayDetailSalud?.map((item, index) => (
                  <View style={comparePDFStyles.tableItemSalud} key={index}>
                    <Text style={comparePDFStyles.titleTextItem}>{item.DESCRIP}</Text>
                    <Text style={comparePDFStyles.item}>{item.MTO30DIAS}</Text>
                    <Text style={comparePDFStyles.item}>{item.CANT30}</Text>
                    <Text style={comparePDFStyles.item}>{item.MTO60DIAS}</Text>
                    <Text style={comparePDFStyles.item}>{item.CANT60}</Text>
                    <Text style={comparePDFStyles.item}>{item.MTO90DIAS}</Text>
                    <Text style={comparePDFStyles.item}>{item.CANT90}</Text>
                    <Text style={comparePDFStyles.item}>{item.MTOMAS90DIAS}</Text>
                    <Text style={comparePDFStyles.item}>{item.CANTMAS90}</Text>
                    <Text style={comparePDFStyles.item}>{item.TOTAL}</Text>
                    <Text style={comparePDFStyles.itemTextRightFinal}>{item.CANTTOTAL}</Text>
                  </View>
                ))}
                {arrayDetailSaludTotal?.map((item, index) => (
                  <View style={comparePDFStyles.tableTotales} key={index}>
                    <Text style={comparePDFStyles.titleTextItemTotal}>TOTAL SALUD</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO30DIAS}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT30TOT}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO60DIAS}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT60TOT}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO90DIAS}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT90TOT}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.MTOMAS90DIAS}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.CANTMAS90TOT}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALAREA}</Text>
                    <Text style={comparePDFStyles.itemTotalFinalRight}>{item.CANTTOTALSUM}</Text>
                  </View>
                ))}
                <View style={{ marginBottom: "10px" }} />


                {arrayDetailAuto?.map((item, index) => (
                  <View style={comparePDFStyles.tableItemAuto} key={index}>
                    <Text style={comparePDFStyles.titleTextItem}>{item.DESCRIP}</Text>
                    <Text style={comparePDFStyles.item}>{item.MTO30DIAS}</Text>
                    <Text style={comparePDFStyles.item}>{item.CANT30}</Text>
                    <Text style={comparePDFStyles.item}>{item.MTO60DIAS}</Text>
                    <Text style={comparePDFStyles.item}>{item.CANT60}</Text>
                    <Text style={comparePDFStyles.item}>{item.MTO90DIAS}</Text>
                    <Text style={comparePDFStyles.item}>{item.CANT90}</Text>
                    <Text style={comparePDFStyles.item}>{item.MTOMAS90DIAS}</Text>
                    <Text style={comparePDFStyles.item}>{item.CANTMAS90}</Text>
                    <Text style={comparePDFStyles.item}>{item.TOTAL}</Text>
                    <Text style={comparePDFStyles.itemTextRightFinal}>{item.CANTTOTAL}</Text>
                  </View>
                ))}
                {arrayDetailAutoTotal?.map((item, index) => (
                  <View style={comparePDFStyles.tableTotales} key={index}>
                    <Text style={comparePDFStyles.titleTextItemTotal}>TOTAL AUTOMÓVIL</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO30DIAS}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT30TOT}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO60DIAS}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT60TOT}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO90DIAS}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT90TOT}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.MTOMAS90DIAS}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.CANTMAS90TOT}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALAREA}</Text>
                    <Text style={comparePDFStyles.itemTotalFinalRight}>{item.CANTTOTALSUM}</Text>
                  </View>
                ))}
                <View style={{ marginBottom: "20px" }} />
                {arrayTotales?.map((item, index) => (
                  <View style={comparePDFStyles.tableTotales} key={index}>
                    <Text style={comparePDFStyles.titleTextItemTotal}>TOTAL GENERAL</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO30DIAS}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT30TOT}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO60DIAS}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT60TOT}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALMTO90DIAS}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.CANT90TOT}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.MTOMAS90DIAS}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.CANTMAS90TOT}</Text>
                    <Text style={comparePDFStyles.itemTotalFinal}>{item.TOTALAREA}</Text>
                    <Text style={comparePDFStyles.itemTotalFinalRight}>{item.CANTTOTALSUM}</Text>
                  </View>
                ))}
              </Page>
            </Document>
          </PDFViewer>
        }
      </Suspense>
    </>
  );
}
